<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12">
		<TableArchiveNok />
	</div>
</template>

<script>
import TableArchiveNok from './TableArchiveNok.vue';

export default {
	name: 'Main',
	components: { TableArchiveNok }
};
</script>
